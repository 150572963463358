<template>
  <div v-if="metadata != null">
    <cargill-crud-meta-view
      :service="crudService"
      :metadata="metadata"
      translatePrefix="application.pages"
      :validation-schema="createValidationSchema"
    />
  </div>
</template>

<script>
import service from '../api/workExceptionService'
import centerService from '../api/centerService'
import { CargillCrudMetaView, getFieldOptions, helpers } from '@cargill/shared'
import _ from 'lodash'
import { generateFieldsSchema } from '@brain/core'
import * as yup from 'yup'

export default {
  components: { CargillCrudMetaView },
  data() {
    return {
      metadata: null,
      crudService: service,
      createValidationSchema: Function
    }
  },
  created() {
    const getMeta = async () => {
      const meta = await service.getMeta()
      const centerField = meta.fields.find((f) => f.id == 'center')
      const centerTypeField = meta.fields.find((f) => f.id == 'centerType')

      let [centerOptions, centersByCenterType] = await Promise.all([
        getFieldOptions(centerField.optionsSource),
        centerService.getCentersByCenterType()
      ])

      const centerOptionsById = _.keyBy(centerOptions, (x) => x.value)
      _.forIn(centersByCenterType, (centers, centerType) => {
        centersByCenterType[centerType] = centers.map(
          (center) => centerOptionsById[center]
        )
      })

      const getCenterOptions = (value) =>
        value?.value == null ? centerOptions : centersByCenterType[value.value]
      centerField.options = centerOptions
      centerTypeField.onValueChange = (changedValue, fields) => {
        helpers.updateOptions(
          this,
          centerField,
          fields.center,
          getCenterOptions(changedValue)
        )
      }

      this.createValidationSchema = (meta, translate) => {
        const validationSchema = generateFieldsSchema(meta, translate)
        helpers.createBaseLocalityValidations(validationSchema, translate)
        return yup.object().shape(validationSchema)
      }

      return meta
    }
    getMeta().then((meta) => {
      this.metadata = meta
    })
  }
}
</script>
